// 
// _nav.scss
// 

.nav-link {
  &.hover {
    color: var(--#{$prefix}primary);
  }
}

.nav-tabs,
.nav-pills {
  .nav-link {
    &.hover {
      color: var(--#{$prefix}primary);
    }

    &.active {
      background-color: var(--#{$prefix}primary);
    }
  }

  >li {
    >a {
      color: var(--#{$prefix}body-color);
      font-weight: $font-weight-medium;
    }
  }
}

.nav-pills {
  >a {
    color: var(--#{$prefix}body-color);
    ;
    font-weight: $font-weight-medium;
  }
}

.nav-tabs {
  border-color: var(--#{$prefix}border-color) !important;

  .nav-link {
    color: $gray-600 !important;

    &:focus,
    &:hover {
      border-color:var(--#{$prefix}border-color) !important;
    }

    &.active {
      color: var(--#{$prefix}body-color);
      background-color: transparent !important;
      border-color: var(--#{$prefix}border-color) !important;
    }
  }
}

.nav-tabs-custom {
  border-bottom: 2px solid var(--#{$prefix}border-color);

  .nav-item {
    position: relative;
    color: $dark;

    .nav-link {
      border: none;

      &::after {
        content: "";
        background: var(--#{$prefix}primary);
        height: 2px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -2px;
        transition: all 250ms ease 0s;
        transform: scale(0);
      }

      &.active {
        color: var(--#{$prefix}primary);

        &:after {
          transform: scale(1);
        }
      }

    }
  }
}

.tabs-custom-justify {
  justify-content: center;
  border-bottom: 1px solid var(--#{$prefix}border-color);

  .nav-link {
    color: lighten($gray-600, 10%);
    padding: .5rem 0.5rem;
    margin: 0px 8px;
    font-weight: $font-weight-medium;
    border-bottom: 1px solid transparent;
    transition: all 0.3s;

    &.active,
    &:hover {
      border-bottom: 1px solid $primary;
      background-color: transparent !important;
      color: $primary;
      border-radius: 0;
      border-left: none !important;
      border-top: none !important;
      border-right: none !important;
    }
  }
}



// vertical nav

.vertical-nav {
  .nav {
    .nav-link {
      padding: 24px 16px;
      text-align: center;
      margin-bottom: 8px;

      .nav-icon {
        font-size: 24px;
      }
    }
  }
}