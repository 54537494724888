.instruction-container {
    
    /* position: fixed;
    top: 0;
    bottom: 20px; */
    
}

@media (max-width: 992px) {
  .instruction-container {
   width: 90vw;
  }
}
