/* test screen css  */

/* Styles for the blurred background */
.blurred-content {
    filter: blur(8px);
    transition: filter 0.3s ease;
    pointer-events: none;
    /* Disable interactions on the blurred content */
}

/* Styles for the overlay */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black */
    z-index: 999;
    /* Below the popup but above everything else */
    pointer-events: all;
    /* Ensure the overlay captures all interactions */
}

/* Styles for the popup */
.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    /* Ensure the popup is on top of everything */
    text-align: center;
    max-width: 400px;
    width: 80%;
}

.popup p {
    font-size: 16px;
    margin-bottom: 20px;
}

.popup button {
    margin: 5px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.popup button:hover {
    background-color: #ddd;
}